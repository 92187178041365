export default {
  "menu": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue à la page 1"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès à votre espace"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte utilisateur"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "fieldnotblank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ doit être renseigné"])},
    "cantlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier vos identifiants."])},
    "checkfield": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier les champs."])}
  },
  "footer": {
    "allrights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous droits réservés"])}
  }
}