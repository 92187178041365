<template>
    <nav class="navbar navbar-expand-md navbar-dark navbar-cc fixed-top">
      <div class="container-fluid">
        <router-link to="/" class="navbar-brand">
          <img alt="Vue logo" src="./assets/logo_cdilconsulting.svg" height="35" class="me-2">
          <strong>CDIL CONSULTING</strong>
        </router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <template v-if="$store.state.isAuthenticated">
            <ul class="navbar-nav me-auto mb-2 mb-md-0">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">Link</a>
              </li>
              <li class="nav-item">
                <a class="nav-link disabled">Disabled</a>
              </li>
            </ul>
            <form class="d-flex" role="search">
              <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
              <button class="btn btn-outline-success" type="submit">Search</button>
            </form>
            <button @click="logout()" class="btn btn-outline-secondary ms-4">Log out</button>

          </template>
          <template v-else>
            <ul class="navbar-nav me-auto mb-2 mb-md-0"></ul>
            <div class="d-flex">
              <router-link to="/log-in" class="btn btn-secondary btn-secondary-cc me-2">{{ t('menu.login') }}</router-link>
            </div>
          </template>
        </div>
      </div>
    </nav>

    <section class="container">
      <router-view />
    </section>

 

  <!-- implicit style (fas is assumed)
  <font-awesome-icon icon="phone" />
  <font-awesome-icon icon="phone" /> 
   -->

    <nav class="navbar fixed-bottom bg-light footer-cc">
      <div class="container-fluid small">
          &copy; 2022 CDIL Consulting - {{ t('footer.allrights') }}
      </div>
    </nav>
</template>

<script>
import axios from "axios";
import { useI18n } from 'vue-i18n';


export default {
  name: "App",
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global'
    })
    return { t }
  },
  beforeCreate() {
    this.$store.commit("initializeStore");
    const token = this.$store.state.token;

    if (token) {
      axios.defaults.headers.common["Authorization"] = "Token " + token;
    } else {
      axios.defaults.headers.common["Authorization"] = "";
    }
  },
  methods: {
    logout() {
        axios
            .post('/api/v1/token/logout/')
            .then(response => {
                axios.defaults.headers.common['Authorization'] = ""
                localStorage.removeItem('token')
                localStorage.removeItem('username')
                localStorage.removeItem('userid')
                this.$store.commit('removeToken')
                this.$router.push('/')
            })
            .catch(error => {
                if(error.response){ console.log(JSON.stringify(error.response.data)); }
                else if(error.message) { console.log(JSON.stringify(error.message)); }
                else { console.log(JSON.stringify(error)); }
            })
    },
  },
};
</script>

<style lang="scss">
</style>

<style lang="scss" src="./assets/css/cc.scss">
/* @import '../assets/css/startpage.css'; */
</style>