<template>
  <div class="dashboard">
    <h1 class="title">{{ t('home.title') }}</h1>
    
  <p>{{ t('message') }}</p>
    <p>Coucou</p>
  </div>
  <HelloI18n></HelloI18n>
</template>

<script>

import { useI18n } from 'vue-i18n'
import HelloI18n from '@/components/HelloI18n.vue';


export default {
  name: 'Dashboard',
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global'
    })
    return { t }
  },
  components: {
    HelloI18n
}
}
</script>
