export default {
  "menu": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client login"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to page 1"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access your client area"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client account"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
  },
  "footer": {
    "allrights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved"])}
  }
}