<template>
  <div class="home">
  </div>
</template>

<script>

import { useI18n } from 'vue-i18n'
import HelloI18n from '@/components/HelloI18n.vue';


export default {
  name: 'HomeView',
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global'
    })
    return { t }
  },
  components: {
    HelloI18n
}
}
</script>
